@import url("https://fonts.googleapis.com/css2?family=Readex+Pro&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: "Readex Pro", sans-serif;
}

a {
  text-decoration: none;
}
p {
  margin: 0;
}

.jr_container{
  max-width: 900px;
  margin: 0 auto;
}

.topbar {
  position: relative;
  padding-left: 60px;
  margin-top: 30px;
}
.topbar_item {
  color: #1da1f2;
  font-weight: bold;
}
.topbar_item::before {
  content: url("../public/logo.svg");
  position: absolute;
  top: -5px;
  left: 20px;
  width: 35px;
  height: 35px;
}



/* /SECTION FAST EASY */
.fast_box {
  max-width: 500px;
  margin: 0 auto;
}
.fast_easy {
  color: white;
  padding: 45px 20px;
  background: linear-gradient(to right, #3590f3 0%, #b235f2 100%);
}
.fast_title {
  font-size: 48px;
}

.fast_content {
  list-style: none;
  padding: 0;
}

.fast_content_iten {
  position: relative;
  font-size: 28px;
  padding-left: 30px;
}

.fast_content_iten::before {
  display: block;
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  top: 10px;
  left: 0px;
  background: url("../public/icon_check.svg") 0 0
    no-repeat;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px 24px;
  background-size: 14px 14px;
}

/* ASKED */
.ask {
  padding: 20px;
}
.ask_title {
  text-align: center;
  font-size: 24px;
}
.aq_box_item {
  font-size: 18px;
  color: #333333;
  text-align: left;
  background-color: #f2f2f2;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 5px;
  padding: 15px;
}
.aq_box_item::after {
  display: block;
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -2px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
  transition: all 0.2s;
}
.aq_box_item.active:after {
  transform: rotate(180deg);
}
.aq_box_item:hover {
  background: linear-gradient(to right, #3590f3 0%, #b235f2 100%);
  color: white;
}
/* .aq_box_item:hover::after{
  transform: rotate(180deg);
} */

.aq_box_item_content {
  font-size: 16px;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
  color: #737373;
}
.aq_box_item_content.active {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.aq_content {
  padding: 20px 30px;
}

/* FOOTER */
.footer {
  padding: 20px;
}
.footer_nav > ul {
  padding: 5px 0;
  display: flex;
  border-top: 1px solid;
  border-bottom: 1px solid;
  
}
.footer_nav_item {
  list-style: none;
  margin-right: 10px;
  border-right: 1px solid;
  padding-right: 10px;
  font-size: 11px;
}
.footer_nav_item>a{
  color: #1da1f2;
}
.footer_nav_item>a:hover{
  color: #b235f2;
}

.footer_content{
  font-size: 11px;
}
.footer_content_item{
  color: #737373;
}

.footer_copyRight{
  text-align: center;
  margin: 20px;
  font-size: 11px;
  color: #737373;

}